import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Form, Col, Row, Typography, Result, Modal, Switch } from 'antd';
import { Layout, Button, message } from 'antd';
import { OpenVidu } from 'openvidu-browser';
import QRCode from 'qrcode.react';
import cx from 'classnames';
import UserVideoComponent from './UserVideoComponent';
import OpenViduVideoComponent from './OvVideo';
import ApiService from '../../utils/api.service';

import JustCastItLogo from './JustCastItLogo.png';
import BackgroundImage from './bg02.jpg';
import './session.css';
const { Header, Content } = Layout;
const { Item } = Form;
const { Title } = Typography;

const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

const AppWrapper = styled(Layout)`
  left: 0;
		right: 0;
		opacity: 1;
  width: 100%;
		height: 100%;
		background-image: url(${BackgroundImage});
		background-size: cover;
`;

const NsgTitle = styled(Title)`
		color: #f0975b !important;
`;

const FormWrapper = styled.div`
	position: absolute;
	left: 50%;
	top: 40%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border-radius: 10px;
	z-index: 1;
	background: #ffffff;
	max-width: 360px;
	margin: 0 auto 100px;
	padding: 45px;
	text-align: center;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
`;

const CastLogo = styled.img`
	width: 100%;
	max-width: 110px;
	margin: -20px auto;
	display: block;
	padding: 0 0 35px 0;
`;

const FormButton = styled.button`
	text-transform: uppercase;
	border-radius: 5px;
	outline: 0;
	background: #f39761;
	font-size: 20px;
	width: 100%;
	border: 0;
	color: #ffffff;
	-webkit-transition: all 0.3 ease;
	transition: all 0.3 ease;
	cursor: pointer;
	box-shadow: 2px 4px 14px #cf8052, 2px 0px 3px #ffae70;
	margin: 0 0 10px;
	padding: 5px;
`;

const NsgImage = ({ url }) => <img style={{ maxWidth: 350, width: '100%' }} src={url} alt={'Logo'} />;

class NsgComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			mySessionId: '',
			myUserName: '',
			sessionIdPopup: false,
			session: undefined,
			mainStreamManager: undefined,
			publisher: undefined,
			subscribers: [],
			compareArraySubscribers: [],
			compareArray: [],
			comparePopupFlag: false,
			compareAll: false
		};

		this.joinSession = this.joinSession.bind(this);
		this.leaveSession = this.leaveSession.bind(this);
		this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
		this.handleChangeUserName = this.handleChangeUserName.bind(this);
		this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
		this.onbeforeunload = this.onbeforeunload.bind(this);
	}

	componentDidMount() {
		window.HELP_IMPROVE_VIDEOJS = false;
		window.addEventListener('beforeunload', this.onbeforeunload);
		this.setState({ myUserName: 'Teacher', mySessionId: Math.floor(100000 + Math.random() * 900000) });
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.onbeforeunload);
	}

	onbeforeunload(event) {
		this.leaveSession();
	}

	handleChangeSessionId(e) {
		this.setState({
			mySessionId: e.target.value
		});
	}

	handleChangeUserName(e) {
		this.setState({
			myUserName: e.target.value
		});
	}

	handleMainVideoStream(stream) {
		if (this.state.mainStreamManager !== stream) {
			this.setState({
				mainStreamManager: stream
			});
		}
	}

	deleteSubscriber(streamManager) {
		let subscribers = this.state.subscribers;
		let index = subscribers.indexOf(streamManager, 0);
		if (index > -1) {
			subscribers.splice(index, 1);
			this.setState({
				subscribers: subscribers
			});
		}
	}

	async joinSession() {
		// ev.preventDefault();

		try {
			let response = await ApiService.tokens({
				username: this.state.myUserName,
				sessionId: this.state.mySessionId
			});

			message.success('Welcome to session, Share your session id with participants so they can join.', 10);
			// --- 1) Get an OpenVidu object ---

			this.OV = new OpenVidu();

			// --- 2) Init a session ---

			this.setState(
				{
					session: this.OV.initSession()
				},
				async () => {
					var mySession = this.state.session;

					// --- 3) Specify the actions when events take place in the session ---

					// On every new Stream received...
					mySession.on('streamCreated', (event) => {
						// Subscribe to the Stream to receive it. Second parameter is undefined
						// so OpenVidu doesn't create an HTML video by its own
						var subscriber = mySession.subscribe(event.stream, undefined);
						var subscribers = this.state.subscribers;
						subscribers.push(subscriber);

						// Update the state with the new subscribers
						this.setState({
							subscribers: subscribers
						});
					});

					// On every Stream destroyed...
					mySession.on('streamDestroyed', (event) => {
						// Remove the stream from 'subscribers' array
						this.deleteSubscriber(event.stream.streamManager);
					});

					// --- 4) Connect to the session with a valid user token ---

					// 'getToken' method is simulating what your server-side should do.
					// 'token' parameter should be retrieved and returned by your own backend

					try {
						// First param is the token got from OpenVidu Server. Second param can be retrieved by every user on event
						// 'streamCreated' (property Stream.connection.data), and will be appended to DOM as the user's nickname
						mySession
							.connect(response.token, { clientData: this.state.myUserName })
							.then(() => {
								// // --- 5) Get your own camera stream ---
								// // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
								// // element: we will manage it on our own) and with the desired properties
								// let publisher = this.OV.initPublisher(undefined, {
								//     audioSource: undefined, // The source of audio. If undefined default microphone
								//     videoSource: 'screen', // The source of video. If undefined default webcam
								//     publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
								//     publishVideo: true, // Whether you want to start publishing with your video enabled or not
								//     resolution: '640x480', // The resolution of your video
								//     frameRate: 30, // The frame rate of your video
								//     insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
								//     mirror: false, // Whether to mirror your local video or not
								// });
								// // --- 6) Publish your stream ---
								// mySession.publish(publisher);
								// Set the main video in the page to display our webcam and store our Publisher
								// this.setState({
								//     mainStreamManager: publisher,
								//     publisher: publisher,
								// });
							})
							.catch((error) => {
								console.log('There was an error connecting to the session:', error.code, error.message);
							});
					} catch (error) {
						message.error(error.message, 2);
					}
				}
			);
		} catch (error) {
			message.error(error.message || 'There is an error while starting new session, Try in sometime.', 10);
		}
	}

	leaveSession() {
		// --- 7) Leave the session by calling 'disconnect' method over the Session object ---

		const mySession = this.state.session;

		if (mySession) {
			mySession.disconnect();
		}

		// Empty all properties...
		this.OV = null;
		this.setState({
			session: undefined,
			subscribers: [],
			mySessionId: Math.floor(100000 + Math.random() * 900000),
			myUserName: '',
			mainStreamManager: undefined,
			publisher: undefined
		});
	}

	addAllToCompare = (ev) => {
		this.setState({ compareAll: !this.state.compareAll, comparePopupFlag: true }, () => {
			const { subscribers } = this.state;
			this.setState({ compareArraySubscribers: [ ...subscribers ], compareArray: [] });
		});
	};
	manageCompare = (value) => {
		const { compareArray } = this.state;
		let index = compareArray.indexOf(value * 1);
		if (index === -1) {
			compareArray.push(value * 1);
		} else {
			compareArray.splice(index, 1);
		}
		this.setState({ compareArray });
	};

	toggleComparePopup = () => {
		let { compareArray, subscribers } = this.state;
		this.setState(
			{ comparePopupFlag: !this.state.comparePopupFlag, compareAll: false, compareArraySubscribers: [] },
			() => {
				let streamArrays = [];
				compareArray.forEach((i) => {
					streamArrays.push(subscribers[i]);
				});

				this.setState({ compareArraySubscribers: [ ...streamArrays ] });
			}
		);
	};

	removeParticipant = (publisher, index) => {
		const mySession = this.state.session;
		let { subscribers } = this.state;

		if (mySession) {
			mySession.forceUnpublish(publisher);
			subscribers.splice(index, 1);
		}

		this.setState({ subscribers });
	};

	removeFromCompareList = (index) => {
		const { compareArraySubscribers } = this.state;
		compareArraySubscribers.splice(index, 1);
		this.setState({ compareArraySubscribers });
	};

	comparePopupUsername = (data) => {
		return JSON.parse(data.split('%/%')[1]).serverData;
	};

	render() {
		const mySessionId = this.state.mySessionId;
		const compareArraySubscribers = this.state.compareArraySubscribers;
		const comparePopupFlag = this.state.comparePopupFlag;
		const compareArray = this.state.compareArray;
		const compareAll = this.state.compareAll;
		return (
			<Fragment>
				{this.state.session === undefined ? (
					<AppWrapper>
						<FormWrapper>
							<CastLogo src={JustCastItLogo} alt="Just Cast IT" />
							<Form onFinish={this.joinSession}>
								<Item>
									<NsgTitle level={4}>Welcome to Just Cast IT. Your Casting ID is below</NsgTitle>
								</Item>
								<NsgTitle level={1}>{mySessionId}</NsgTitle>

								<FormButton htmlType="submit">Start Casting Session</FormButton>
							</Form>

							<p className="message">
								<a
									href="https://www.touchittechnologies.com/justcastit"
									target="_blank"
									rel="noopener noreferrer"
								>
									I Need Help
								</a>
							</p>

							<img
								style={{ width: 140 }}
								src="https://touchittechnologies.com/img/TouchIT-Logo-Clear-PNG-200-x-67.png"
								alt="Touch IT Technologies"
							/>
						</FormWrapper>
					</AppWrapper>
				) : null}

				{this.state.session !== undefined ? (
					<Layout style={{ height: '100%', background: 'inherit' }}>
						<Header style={{ background: '#ffffff' }}>
							<img
								style={{ width: 140, float: 'left', marginTop: 10 }}
								src="https://touchittechnologies.com/img/TouchIT-Logo-Clear-PNG-200-x-67.png"
								alt="Touch IT Technologies"
							/>
							<span
								onClick={() => this.setState({ sessionIdPopup: !this.state.sessionIdPopup })}
								style={{ cursor: 'pointer', color: '#f0975b', float: 'right', fontSize: 'xx-large' }}
							>
								Casting ID: {mySessionId}
							</span>
						</Header>
						<Content style={{ padding: '7px 20px' }}>
							<div
								className={cx('nsg-comparecastpopupcontainer', {
									'nsg-comparecastopenpopup': comparePopupFlag
								})}
							>
								<Button
									style={{
										height: 34,
										zIndex: 15,
										padding: 2,
										background: '#f0975b',
										position: 'absolute'
									}}
									type="link"
									onClick={this.toggleComparePopup}
									className={'nsg-comparecastclonsebtn'}
								>
									<CloseOutlined />
								</Button>

								{compareArraySubscribers.map((sub, i) => (
									<div key={getRandomInt(100)} className={'nsg-comparecast'}>
										<figure className={'at-screenimg'}>
											<div className={'nsg-studentscreenholder'}>
												<label className={'nsg-studentname'}>
													{this.comparePopupUsername(sub.stream.connection.data)}
													<Button
														type="link"
														onClick={this.removeFromCompareList.bind(this, i)}
													>
														<CloseOutlined />
													</Button>
												</label>
												<OpenViduVideoComponent streamManager={sub} />
											</div>
										</figure>
									</div>
								))}
							</div>

							{!this.state.subscribers.length && (
								<Result
									icon={<NsgImage url="/logo_white.png" />}
									title="Session Started Successfully"
									subTitle={<div>
                  Waiting for participants to join
                  <br />
                  <span>If you can't see your participants after they have joined your session, scroll down for more information.</span>
                  <div style={{ marginBottom: 400, display: 'block', width: '100%' }}></div>
                  <span>On the LED, if when your participants join, you do not see their screens, then please follow the instructions below.</span>
                  <br />
                  <span>Open Chrome Browser on your LED and navigate to   chrome://flags and then search for Autoplay. Set this option to “No user gesture is required”</span>
                  <br />
                  <img style={{width: 800, height: 400}} src="/led_instructions.jpg" />
                  </div>}
								/>
							)}

							{this.state.subscribers.length ? (
								<div style={{ display: 'block', height: 50 }}>
									<Switch
										checkedChildren="Disable Multi-Cast"
										unCheckedChildren="&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; Add all to Multi-Cast"
										checked={!!compareAll}
										onClick={this.addAllToCompare}
									/>

									<Button
										style={{ width: 182, fontSize: 14, float: 'right' }}
										onClick={this.toggleComparePopup}
									>
										View Multi-Cast Window
									</Button>
								</div>
							) : null}
							<Row style={{ margin: 0 }} gutter={[ 8, 48 ]}>
								{this.state.subscribers.map((sub, i) => (
									<Col
										style={{ background: '#ffffff', padding: '5px 4px 0px' }}
										key={getRandomInt(100)}
										span={6}
									>
										<UserVideoComponent
											checked={compareArray.indexOf(i) > -1}
											mannageCompare={this.manageCompare.bind(this, i)}
											streamManager={sub}
											removeParticipant={() => this.removeParticipant(sub, i)}
										/>
									</Col>
								))}
							</Row>
						</Content>
					</Layout>
				) : null}
				<Modal
					visible={this.state.sessionIdPopup}
					footer={null}
					onCancel={() => this.setState({ sessionIdPopup: !this.state.sessionIdPopup })}
					centered={true}
					closable={false}
					width={600}
				>
					<Row gutter={[ 8, 48 ]}>
						<Col span={10}>
							<QRCode value="https://justcastit.com/" renderAs={'svg'} size={200} />
						</Col>
						<Col
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								alignItems: 'center',
								justifyContent: 'center'
							}}
							span={14}
						>
							<div style={{ textAlign: 'center' }}>
								To cast to this session, please open your browser (Chrome or Firefox) and navigate to
								https://justcastit.com Enter your name and this
							</div>
							<Title style={{ fontSize: 95, margin: 0, color: '#f39760' }}>{mySessionId}</Title>
						</Col>
					</Row>

					<FormButton
						onClick={() => {
							var textArea = document.createElement('textarea');

							textArea.style.position = 'fixed';
							textArea.style.top = 0;
							textArea.style.left = 0;
							textArea.style.width = '2em';
							textArea.style.height = '2em';
							textArea.style.padding = 0;
							textArea.style.border = 'none';
							textArea.style.outline = 'none';
							textArea.style.boxShadow = 'none';
							textArea.style.background = 'transparent';
							textArea.value = mySessionId;
							document.body.appendChild(textArea);
							textArea.select();
							try {
								var successful = document.execCommand('copy');
								var msg = successful ? 'successful' : 'unsuccessful';
								console.log('Copying sessionId command was ' + msg);
							} catch (err) {
								console.log('Oops, unable to copy');
							}
							document.body.removeChild(textArea);

							this.setState({ sessionIdPopup: !this.state.sessionIdPopup });
						}}
					>
						Copy & Close
					</FormButton>
				</Modal>
			</Fragment>
		);
	}
}

export default NsgComponent;
