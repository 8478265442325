import React, { Component, Fragment } from 'react';
import { Switch } from 'antd';
import { AudioOutlined, CloseOutlined } from '@ant-design/icons';

import OpenViduVideoComponent from './OvVideo';
import './UserVideo.css';

export default class UserVideoComponent extends Component {
	constructor(props) {
		super(props);
		this.videoRef = React.createRef();
		this.state = {
			mute: false
		};
	}
	getNicknameTag() {
		// return 'Asif Javed';
		// Gets the nickName of the user
		return JSON.parse(this.props.streamManager.stream.connection.data.split('%/%')[1]).serverData;
	}

	render() {
		return (
			<div style={{ position: 'relative' }}>
				{this.props.streamManager !== undefined ? (
					<Fragment>
						<span>{this.getNicknameTag()}</span>
						<CloseOutlined
							onClick={this.props.removeParticipant}
							style={{
								pointer: 'cursor',
								position: 'absolute',
								right: 0,
								background: 'red',
								borderRadius: '50%',
								color: 'white',
								padding: 2
							}}
						/>

						<AudioOutlined
							onClick={() => this.videoRef.current.toggleMute()}
							style={{
								pointer: 'cursor',
								position: 'absolute',
								right: 20,
								background: '#f0975b',
								borderRadius: '50%',
								color: 'white',
								padding: 2
							}}
						/>

						<Switch
							checkedChildren="Remove From Multi-Cast"
							unCheckedChildren="&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; Add to Multi-Cast"
							checked={this.props.checked}
							onClick={this.props.mannageCompare}
							style={{
								background: '#f0975b',
								position: 'absolute',
								bottom: 10,
								right: 0,
								zIndex: 2
							}}
						/>

						<OpenViduVideoComponent ref={this.videoRef} streamManager={this.props.streamManager} />
					</Fragment>
				) : null}
			</div>
		);
	}
}
