import React, { Component } from 'react';
export default class OpenViduVideoComponent extends Component {
	constructor(props) {
		super(props);
		this.videoRef = React.createRef();
		this.videoId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
	}

	componentDidUpdate(props) {
		if (props && !!this.videoRef) {
			this.props.streamManager.addVideoElement(this.videoRef.current);
			this.videoRef.current.play();
			// this.videoRef.current.muted = true;

			setInterval(() => {
				if (this.videoRef && this.videoRef.current && this.videoRef.current.paused) {
					this.videoRef.current.play();
				}
			}, 2000);
		}
	}

	componentDidMount() {
		if (this.props && !!this.videoRef) {
			this.props.streamManager.addVideoElement(this.videoRef.current);
			// this.videoRef.current.muted = true;

			setInterval(() => {
				if (this.videoRef && this.videoRef.current && this.videoRef.current.paused) {
					this.videoRef.current.play();
				}
			}, 2000);
		}
	}

	viewFullScreen = () => {
		if (this.videoRef.current.requestFullscreen) {
			this.videoRef.current.requestFullscreen();
		} else if (this.videoRef.current.mozRequestFullScreen) {
			this.videoRef.current.mozRequestFullScreen();
		} else if (this.videoRef.current.webkitRequestFullscreen) {
			this.videoRef.current.webkitRequestFullscreen();
		} else if (this.videoRef.current.msRequestFullscreen) {
			this.videoRef.current.msRequestFullscreen();
		}
	};

	toggleMute = () => {
		this.videoRef.current.muted = !this.videoRef.current.muted;
		return this.videoRef.current.muted;
	};

	makeFullScreen = () => {
		let divObj = this.videoRef.current;
		if (
			!document.fullscreenElement && // alternative standard method
			!document.mozFullScreenElement &&
			!document.webkitFullscreenElement &&
			!document.msFullscreenElement
		) {
			if (divObj.requestFullscreen) {
				divObj.requestFullscreen();
			} else if (divObj.msRequestFullscreen) {
				divObj.msRequestFullscreen();
			} else if (divObj.mozRequestFullScreen) {
				divObj.mozRequestFullScreen();
			} else if (divObj.webkitRequestFullscreen) {
				divObj.webkitRequestFullscreen();
			} else {
				console.log('Fullscreen API is not supported');
			}
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	};

	render() {
		return (
			<video
				style={{ width: '100%' }}
				id={this.videoId}
				controls={false}
				autoPlay={true}
				ref={this.videoRef}
				src={this.props.streamManager}
				onDoubleClick={this.makeFullScreen}
				muted
			/>
		);
	}
}
